/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 140px 0 0 0;
   $body-sticky-padding: 140px 0 0 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;


/* ==========================================================================
   content
   ========================================================================== */
   
   $content-padding: 6vh 0;

/*  content > h1
    ========================================================================== */
    $content-h1-font_size: 1.875rem; /* 30px */
    $content-h1-font_size_sm: 2.25rem; /* 36px */
    $content-h1-color: $headings-color;

/*  content > h2
    ========================================================================== */
    $content-h2-font_size: 1.5rem; /* 24px */
    $content-h2-font_size_sm: 1.625rem; /* 26px */
    $content-h2-color: $headings-color;

/*  content > h3
    ========================================================================== */
    $content-h3-font_size: 1.5rem; /* 24px */
    $content-h3-font_size_sm: 1.5rem; /* 24px */
    $content-h3-color: $headings-color;

/*  content > h4
    ========================================================================== */
    $content-h4-font_size: 1.25rem; /* 20px */
    $content-h4-font_size_sm: 1.25rem; /* 20px */
    $content-h4-color: $headings-color;

/*  content > h5
    ========================================================================== */
    $content-h5-font_size: 1.125rem; /* 18px */
    $content-h5-font_size_sm: 1.125rem; /* 18px */
    $content-h5-color: $headings-color;

/*  content > h6
    ========================================================================== */
    $content-h6-font_size: 1rem; /* 16px */
    $content-h6-font_size_sm: 1rem; /* 16px */
    $content-h6-color: $headings-color;


/*  content > ol.custom-list
    ========================================================================== */

    /* content > ol.custom-list > icon */
    $content-ol-custom-list-item-icon: '\f00c';
    $content-ol-custom-list-item-icon-font_size: $font-size-base;
    $content-ol-custom-list-item-icon-font_weight: $font-weight-bold;


/* ==========================================================================
    card
    ========================================================================== */
    $card-background: #fff;
    $card-base-border-radius: 0;
    $card-box_shadow: 0 3px 6px rgba($black, 0.15);

/*  card > card-body
    ========================================================================== */
    $card-body-padding: 15px;

/*  card > card-buttons
    ========================================================================== */
    $card-buttons-margin: auto 0 0 0;

/*  card > card-caption
    ========================================================================== */
    $card-caption-flex: 0 1 auto;

    /* card > card-title */
    $card-title-font_size: $font-size-base * 1.5;
    $card-title-font_size_sm: $font-size-base * 1.5;
    $card-title-color: $headings-color;

    /* card > card-description */
    $card-description-max_height: auto;
    $card-description-margin: 0 0 15px 0;
    $card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);


/*  ==========================================================================
    owl-carousel
    ========================================================================== */
    $carousel-item_padding_lg: 10vh 0;
    $carousel-min_height_lg: 50vh;

/*  ==========================================================================
    gallery
    ========================================================================== */
    $gallery-caption-background: rgba($purple,.7);

    $gallery-caption-font_size: $font-size-base * 1.125;
    $gallery-caption-font_size_sm: $font-size-base * 1.125;
    
/*  ==========================================================================
    footer
    ========================================================================== */
    $footer-top-padding: 0;
    $footer-top-background: none;