/*  bootstrap > colors
    ========================================================================== */
    $black:         #000;
    $purple:        #7d3a83;
    $purple-dark:   #602c65;
    $gray:          #545454;
    $gray-light:    #f3f2f2;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:       $purple;
    $secondary:     $purple-dark;

    $dark:          $gray;

/*  bootstrap > body
    ========================================================================== */
    $body-bg:       $gray-light;
    $body-color:    $gray;

    $link-color:    $primary;
    $link-hover-decoration: none; 

/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

    $font-family-rajdhani: 'Rajdhani', sans-serif;
    $font-family-base: $font-family-rajdhani;

/* bootstrap > typography
    ========================================================================== */
    $font-weight-base:   500;
    $headings-color:    $black;

/*  misc
    ========================================================================== */   

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}

    /* gradient (extend) */
    .gradient { background: linear-gradient(135deg, rgba(125,58,131,1) 0%, rgba(63,29,66,1) 100%);}
    .gradient-reverse { background: linear-gradient(135deg, rgba(63,29,66,1) 0%, rgba(125,58,131,1) 100%);}

    /*
    Logo: 410 x 53
    Caption: 50% zwart

    Kleuren:
    Gradient:  

    Font:
    Tekst: #545454
    16px medium (500)
    Koppen: #000
    Paars: #7d3a83
    Paars donker: #602c65
    Paars donker (gradient): 3f1d42

    Shadow: 0 3px 6px rgba 15% zwart
    */