.eyecatcher {
	position: relative;
	.owl-carousel {
		.owl-item.active .item .owl-caption { animation: 1s .25s slideInLeft both;}
		.item {
			.owl-caption { 
				max-width: 700px;
				padding: 15px;
                background-color: rgba($color: #000000, $alpha: 0.5);
                color: $white;
				.owl-title {
                    margin-bottom: 5px;
					font-size: 36px;
					line-height: 1;
                    font-weight: 700;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
                        font-size: 48px;
					}
				}
				.owl-subtitle {
                    margin-bottom: 0;
					font-size: 18px;
					line-height: 1;
					font-weight: 500;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
                        font-size: 24px;
					}
				}
			}
		}
	}
}