/*  footer
    ========================================================================== */
    .footer {
        position: relative;

        /* footer-top */
        .footer-top {
            padding: 6vh 0 0 0;
            background-color: $white;
            .column.one {
                @include media-breakpoint-down(sm) {
                    order: 1;
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(7);
                }
                //background: url('/images/stel.jpg') no-repeat right 100%;
                padding-bottom: 120px;
                font-size: 18px;
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li a[href^="tel:"],
                    li a[href^="mailto:"] {
                        color: $purple-dark;
                        font-size: 18px;
                        font-weight: 700;
                    }
                    li a[href^="tel:"]::before {
                        content: "\f095";
                        font-family: "Font Awesome 5 Pro";
                        margin-right: 10px;
                        color: $purple-dark;
                        font-weight: 900;
                    }
                    li a[href^="mailto:"]::before {
                        content: "\f0e0";
                        font-family: "Font Awesome 5 Pro";
                        margin-right: 10px;
                        color: $purple-dark;
                        font-weight: 900;
                    }
                    li a[href^="tel:"]:hover,
                    li a[href^="mailto:"]:hover { color: $purple;}
                }
            }
            .column.two {
                margin-bottom: 30px;
                @include media-breakpoint-up(lg) {
                    @include make-col(5);
                }
            }

            h2 {
                font-size: 1.875rem; /* 30px */
                @include media-breakpoint-up(sm) {
                    font-size: 2.25rem; /* 36px */
                }
            } 
            .offer-box {
                padding: 15px;
                @include media-breakpoint-up(lg) {
                    padding: 30px;
                }
                border-radius: 3px;
                box-shadow: 0 3px 6px rgba($color: $black, $alpha: 0.15);
                border-color: $primary;
                @extend .gradient;
                color: #fff;
                h3 {
                    color: #fff;
                    font-size: 1.25rem; /* 20px */
                    @include media-breakpoint-up(sm) {
                        font-size: 1.5rem; /* 24px */
                    }
                } 
            }
        }

        /* footer-bottom */
        .footer-bottom {
            padding: 5vh 0 30px 0;
            background-color: $black;
            color: #fff;
            font-size: 14px;
            h5 {
                color: #fff;
                font-size: 16px;
            }
            .footer_list {
                .list {
                    .list-item {
                        .link {
                            color: $white;
                            &:hover { color: $primary;}
                        }
                    }
                }
            }
            .footer_logolink {
                .list {
                    .list-item {
                        margin: 0 5px 5px 5px;
                        .link {
                            display: block;
                            width: 28px;
                            height: 28px;
                            border-radius: 100%;
                            background: $white;
                            color: $purple-dark;
                            font-size: 16px;
                            line-height: 28px;
                            text-align: center;
                            i { line-height: 28px;}
                            &:hover {
                                background: $purple;
                                color: $white;
                            }
                        }
                    }
                }
            }
            .copyright {
                margin-top: 30px;
                padding-top: 15px;
                border-top: 1px solid $white;
                .list {
                    .list-item {
                        .link {
                            color: $white;
                            &:hover { color: $primary;}
                        }
                    }
                }
            }
        }

    }
