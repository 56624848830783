/*  usps
    ========================================================================== */
    .usps {
        margin: 10px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                margin: 10px 30px;
                color: $primary;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                i {
                    margin-right: 10px;
                    font-size: 18px;
                }
            }
        }
    }

/*  intro
    ========================================================================== */
    .intro {
        padding-top: 4vh;
        .container-holder {
            max-width: 680px;
        }
    }

/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }

/*  inspiration-blocks
    ========================================================================== */
    .inspiration-blocks {
        background: url('/images/inspiratie-bg.png') repeat top left #262626;
        color: $white;
        .intro-text {
            h2 {
                color: $white;
            }   
        }
    }

/*  intro-text
    ========================================================================== */
    .intro-text {
        margin-bottom: 30px;
        h2 { margin-bottom: 0;}   
        p { font-size: 18px;}
    }

/*  reviews
    ========================================================================== */
    &.home {
        .reviews {
            padding: 3vh 0 6vh 0;
            text-align: center;
            .container-holder {
                max-width: 640px;
                margin: 0 auto;
            }
            .owl-carousel {
                min-height: 0;
                padding: 0 30px;
                @include media-breakpoint-up(sm) {
                    padding: 0 60px;
                }
                &::before {
                    @extend .d-none;
                }
                .item {
                    min-height: 0;
                    padding: 0;
                    .owl-caption {
                        text-align: center;
                        .owl-description {
                            color: $black;
                            text-shadow: none;
                            .rating {
                                margin: 15px 0;
                                color: $purple;
                            }
                            .date {
                                @extend .d-none;
                            }
                        }
                    }
                }
                .owl-nav {
                    .owl-prev,
                    .owl-next { 
                        color: $purple;
                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    .reviews-overview {
        .item {
            margin-top: 30px;
            .review {
                padding: 15px;
                background-color: #fff;
                box-shadow: 0 3px 6px rgba($black, 0.15);
                .rating {
                    margin: 15px 0;
                    color: $purple;
                }
                //.date {
                //    @extend .d-none;
                //}
            }
        }
    }