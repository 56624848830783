.btn {
    min-width: 185px;
    padding: 0.313rem 0.75rem;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba($color: $black, $alpha: 0.15);
    border-color: transparent;
    @extend .gradient;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
        @extend .gradient-reverse;
        color: #fff;
    }
    &.btn-secondary {
        background: $white;
        color: $purple;
        &:hover {
            background-color: $black;
            color: $white;
        }
    }
}