/*  default
    ========================================================================== */
    .card {
        .card-body {
            .card-caption {
                .card-description {
                    &:after {
                        display: none !important;
                    }
                }
            }
            .card-buttons {
                .card-btn {
                    @extend .gradient;
                    &:hover {
                        @extend .gradient-reverse;
                    }
                }
            }
        }
    }

/*  round-blocks
    ========================================================================== */
    .round-blocks {
        .grid {
            justify-content: center;
            @include media-breakpoint-up(xl) {
                justify-content: flex-start;
            }
            .item {
                @include media-breakpoint-down(sm) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(3);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(2);
                }
            }
        }
        .card {
            background: none;
            border: none;
            box-shadow: none;
            &:hover {
                .card-image {
                    opacity: 0.8;
                }
            }
            .card-image {
                margin: 0 auto 15px auto;
                transition: 0.5s;
                border-radius: 100%;
                background: none;
                overflow: hidden;
                .card-img {
                    width: 165px;
                    height: 165px;
                    @include media-breakpoint-down(xs) {
                        width: 150px;
                        height: 150px;
                    }
                    border-radius: 100%;
                }
            }
            .card-body {
                padding: 0;
                background: none;
                .card-caption {
                    .card-title {
                        margin-bottom: 0;
                        color: $gray;
                        font-size: 18px;
                        text-align: center;
                    }
                    .card-subtitle {
                        margin-bottom: 0;
                        text-align: center;
                    }
                    .card-description {
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
                .card-buttons {
                    margin: auto auto 0 auto;
                    .card-btn {
                        min-width: 0;
                    } 
                }
            }
        }
    }

/*  inspiration-blocks
    ========================================================================== */
    .inspiration-blocks {
        .card-columns {
            @include media-breakpoint-up(xl) {
                column-count: 3;
            }
        }
        .item {
            .card {
                background: none;
                border: none;
                box-shadow: none;
                .card-image {
                    margin-bottom: 10px;
                }
                .card-body {
                    padding: 0;
                    background: none;
                    .card-caption {
                        .card-title {
                            margin-bottom: 0;
                            color: $white;
                            font-size: 14px;
                        }
                        .card-subtitle {
                            margin-bottom: 0;
                        }
                        .card-description {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
