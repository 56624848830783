.header {
    display: flex;
    flex-direction: column;
    background-color: $white;
    /* header-top */
    .header-top {
        @include media-breakpoint-down(lg) {
            order: 1;
        }
        padding: 15px 0;
        @include media-breakpoint-up(xl) {
            padding: 20px 15px;
        }
        background-color: $white;
        .navbar {
            padding: 0;
            @include media-breakpoint-down(sm) {
                justify-content: center;
                .logo {
                    width: 100%;
                    max-width: none;
                    margin: 0 auto 10px auto;
                    a {
                        max-width: 270px;
                        margin: 0 auto;
                    }
                }
            }
        }
        .logo {
            max-width: 270px;
            margin-right: auto;
            transition: 0.5s;
            @include media-breakpoint-up(lg) {
                max-width: 350px;
            }
            @include media-breakpoint-up(xl) {
                max-width: 410px;
            }
            a { display: block;
                img { width: 100%;}
            }
        }
        .quick-link {
            margin-bottom: 0;
            font-weight: 700;
        }
        .socials {
            flex-direction: row;
            .nav-item {
                margin: 5px;
                .nav-link {
                    display: block;
                    width: 28px;
                    height: 28px;
                    padding: 0 !important;
                    border-radius: 100%;
                    border: 1px solid $purple;
                    background: $white;
                    color: $purple-dark;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    i { line-height: 26px;}
                    &:hover {
                        background: $purple;
                        color: $white;
                    }
                }
            }
        }
        .quick-button {
            margin-bottom: 0;
            li {
                margin: 0 0 0 10px;
                a { @extend .btn;}
            }
            @include media-breakpoint-down(lg) {
                display: none !important;
            }
        }
    }
    
    /* header-main */
    .header-main {
        position: relative;
        @include media-breakpoint-down(lg) {
            order: 0;
            padding: 0;
            background: linear-gradient(135deg, rgba(125,58,131,1) 0%, rgba(63,29,66,1) 100%);
        }
        padding: 5px 0;
        @include media-breakpoint-up(xl) {
            padding: 5px 30px;
        }
        background-color: $black;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.15);
        .navbar {
            padding: 0;
            .navbar-toggler {
                display: flex;
                border: none;
                outline: none;
                margin: 0;
                padding: 0 25px;
                background-color: $black;
                border-radius: 0;
                color: $white;
                font-size: 16px;
                font-weight: 700;
                line-height: 50px;
                @include media-breakpoint-up(xl) {
                    display: none !important;
                }
                i { margin-right: 8px;}
            }
            .menu {
                @include media-breakpoint-down(lg) {
                    order: 4;
                    padding: 5px 15px;
                }
                .navbar-nav {
                    margin: 5px 0;
                    .nav-item {
                        @include media-breakpoint-down(lg) {
                            border-top: 1px solid rgba($white, 0.3);
                            &:first-of-type { border-top: none;}
                        }
                        @include media-breakpoint-up(xl) {
                            margin-right: 30px;
                        }
                        font-size: 16px;
                        font-weight: 700;
                        .nav-link {
                            @include media-breakpoint-up(xl) {
                               padding: 0;
                            }
                            color: $white;  
                            line-height: 27px;
                            text-decoration: none;
                            @include media-breakpoint-up(xl) {
                                border-bottom: 3px solid transparent;
                            }
                            &:hover {
                                border-color: $white;
                                @include media-breakpoint-down(xl) {
                                    color: $white;
                                }
                            } 
                        }
                        &.active a {
                            border-color: $white;
                            @include media-breakpoint-down(lg) {
                                color: $white;
                            }
                        }  
                        .dropdown-menu {
                            min-width: 100%;
                            @include media-breakpoint-down(lg) {
                                margin: 0 0 0 15px;
                                border-top: 1px solid rgba($white, 0.3) !important;
                            }
                            @include media-breakpoint-up(xl) {
                                background-color: $black;
                                border: 2px solid $white;
                            }
                            margin: 0 0 0 -15px;
                            padding: 5px 0;
                            .nav-item {
                                margin: 0;
                                text-transform: none;
                                .nav-link {
                                    margin-left: 0;
                                    padding: 0 15px;
                                    @include media-breakpoint-down(lg) {
                                        padding: 5px 0;
                                    }
                                    color: $white;
                                    font-weight: 700;
                                    border-bottom: none;
                                    white-space: nowrap;
                                    &:hover {
                                        color: $purple;
                                    }
                                }
                                &.active a {
                                    color: $purple;
                                } 
                            }
                        }
                    }
                }
                &.show {
                    padding: 5px 15px;
                    .navbar-nav {
                        .dropdown-menu { 
                            background: transparent;
                            border: none;
                            .nav-item {
                                .nav-link { color: $white;
                                    &:hover {
                                        color: $white;
                                    }
                                }
                                &.active a {
                                    color: $white;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* strizo-dealer */
        .strizo-dealer {
            @include media-breakpoint-down(sm) {
                max-width: 150px;
                margin-right: 15px;
            }
            @include media-breakpoint-up(md) {
                max-width: 200px;
            }
            @include media-breakpoint-up(xl) {
                max-width: 285px;
            }
        }

        /* quick-button */
        .quick-button {
            margin-bottom: 0;
            padding: 0 20px;
            li {
                margin: 0 0 0 10px;
                a {
                    line-height: 50px;
                    color: $white;
                    font-weight: 700;
                    &:hover { text-decoration: underline;}
                }
            }
            @include media-breakpoint-down(xs) {
                display: none !important;
            }
            @include media-breakpoint-up(xl) {
                display: none !important;
            }
        }

    }      

    /* direct-contact */
    .direct-contact {
        align-items: center;
        background-color: $yellow;
        border-radius: 20px;
        margin: 0 0 0 auto;
        @include media-breakpoint-up(xl) {
            margin-bottom: 10px;
        }
        padding: 0 15px;
        color: #555;
        font-size: 14px;
        li {
            margin: 0;
            &:first-of-type {
                margin-right: 5px;
            }
            i {
                margin-right: 5px;
            } 
            a {
                color: $black;
                font-size: 20px;
                font-weight: 700;
                font-style: italic;
                text-decoration: none;
                &:hover {
                    color: $blue;
                }
            }
        }
    }  
    

/*  sticky
    ========================================================================== */
    @include media-breakpoint-up(xl) {
        &.sticky {
            .header-top {
                .logo {
                    max-width: 270px;
                }
            }
        }
    }
}