.gallery-grid {
  .gallery-item {
    .gallery-caption {
      bottom: unset;

      padding: 10px;

      text-transform: uppercase;
      text-align: right;

      hr {
        max-width: 120px;
        height: 0;
        margin: 5px 0 0 auto;

        border-bottom: 3px solid #fff;
      }
    }
  }
}