.page_block.service_overview {
    &.grid {
        justify-content: flex-start;
        margin: 30px -15px 30px -15px;
        .item {
            .card {
                .card-image {
                    .card-image-link {
                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
                .card-body {
                    .card-caption {
                        .card-title {
                            font-size: 1.2rem;
                        }
                        .card-subtitle {
                            font-size: 0.8rem;
                            font-weight: 600;
                        }
                        .card-description {
                            display: none;
                        }
                    }
                    .card-buttons {
                        .card-btn {
                            width: 1005%;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}

.service_detail {
    .info {
        h1 {
            margin: 30px 0;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
        .extra_description {
            h3 {
                font-size: 1.2rem;
                margin: 10px 0;
            }
            p {
                margin-bottom: 0.5rem;
            }
            .btn {
                margin: 30px 0;
            }
        }
        .description {
            h2 {
                font-size: 1.2rem;
            }
        }
    }
}