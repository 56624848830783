/*  intro
    ========================================================================== */
    &.home .intro { padding: 4vh 0 0 0;}

/*  content
    ========================================================================== */
    &.home .content h2 { margin-bottom: 0;}

/*  home-blocks
    ========================================================================== */
    &.home .home-blocks {
        padding: 4vh 0;
    }

/*  inspiration-blocks
    ========================================================================== */
    &.home .inspiration-blocks {
        padding: 6vh 0;
    }

/*  h2
    ========================================================================== */
    &.home h2 {
        font-size: 1.875rem; /* 30px */
        @include media-breakpoint-up(sm) {
            font-size: 2.25rem; /* 36px */
        }
    }