/* fancybox */
.fancybox-content {
    border: 10px solid $white;
}
.fancybox-caption {
    color: $white;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

a[id]:not([href]) {
    position: absolute;
    margin-top: -428px;     
} 